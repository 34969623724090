let data = [
    {
        name: "Hangman",
        URL: "https://nitalewska.github.io/hangman/",
        thumbnail: "../projects/thumbnails/hangman.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: true,
        pet: true,
        game: true,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Coffee House Cafe",
        URL: "https://nitalewska.github.io/coffee-house/home",
        thumbnail: "../projects/thumbnails/coffee-house.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: true,
        pet: true,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Nonograms",
        URL: "https://nitalewska.github.io/nonograms/",
        thumbnail: "../projects/thumbnails/nonograms.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: true,
        pet: true,
        game: true,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Four card feature section",
        URL: "../projects/four-card-feature-section/index.html",
        thumbnail: "../projects/thumbnails/four-card-feature-section.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Space Tourism multipage website",
        URL: "https://nitalewska.github.io/space-tourism-multi-page-website/index.html",
        thumbnail: "../projects/thumbnails/space-tourism-multi-page-website.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "intermediate",
        favourite: true,
        pet: false,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Huddle landing page",
        URL: "../projects/huddle-landing-page-with-single-introductory-section/index.html",
        thumbnail: "../projects/thumbnails/huddle-landing-page-with-single-introductory-section.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Ping coming soon page",
        URL: "../projects/ping-single-column-coming-soon-page/index.html",
        thumbnail: "../projects/thumbnails/ping-single-column-coming-soon-page.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Stats preview card component",
        URL: "../projects/stats-preview-card-component/index.html",
        thumbnail: "../projects/thumbnails/stats-preview-card-component.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "CAPSULE Landing page",
        URL: "https://nitalewska.github.io/Kwork-CAPSULE/",
        thumbnail: "../projects/thumbnails/capsule.jpg",
        frontendmentor: false,
        freelance: true,
        difficulty: "",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Astro7 Landing Page",
        URL: "https://nitalewska.github.io/astro7-promo-tarot/",
        thumbnail: "../projects/thumbnails/astro7-promo-tarot.jpg",
        frontendmentor: false,
        freelance: true,
        difficulty: "",
        favourite: true,
        pet: false,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Single price grid component",
        URL: "../projects/single-price-grid-component/index.html",
        thumbnail: "../projects/thumbnails/single-price-grid-component.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Intro component with sign-up form",
        URL: "../projects/Intro-component-with-sign-up-form/index.html",
        thumbnail: "../projects/thumbnails/intro-component-with-sign-up-form.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Base Apparel coming soon page",
        URL: "../projects/base-apparel-coming-soon-page/index.html",
        thumbnail: "../projects/thumbnails/base-apparel-coming-soon.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Social proof section",
        URL: "../projects/social-proof-section/index.html",
        thumbnail: "../projects/thumbnails/social-proof-section.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "FAQ accordion card",
        URL: "../projects/FAQ-accordion-card/index.html",
        thumbnail: "../projects/thumbnails/FAQ-accordion-card.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Profile card component",
        URL: "../projects/profile-card-component/index.html",
        thumbnail: "../projects/thumbnails/profile-card-component.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Preview card component",
        URL: "../projects/3-column-preview-card-component/index.html",
        thumbnail: "../projects/thumbnails/3-column-preview-card-component.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Meet Landing page",
        URL: "../projects/meet-landing-page/index.html",
        thumbnail: "../projects/thumbnails/meet-landing-page.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "newbie",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "CARAT Landing page",
        URL: "https://nitalewska.github.io/Kwork-CARAT/",
        thumbnail: "../projects/thumbnails/CARAT-landing-page.jpg",
        frontendmentor: false,
        freelance: true,
        difficulty: "",
        favourite: true,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Quiz",
        URL: "https://nitalewska.github.io/quiz-react/",
        thumbnail: "../projects/thumbnails/quiz.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: true,
        pet: true,
        game: true,
        langs: "HTML/CSS/ReactJS/API",
        description: ""
    },
    {
        name: "Clipboard Landing page",
        URL: "https://nitalewska.github.io/clipboard-landing-page/",
        thumbnail: "../projects/thumbnails/clipboard-landing-page.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "junior",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "Sunnyside agency Landing page",
        URL: "https://nitalewska.github.io/Sunnyside-agency-landing-page/",
        thumbnail: "../projects/thumbnails/sunnyside-agency-landing-page.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "junior",
        favourite: true,
        pet: false,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Testimonials Grid Section",
        URL: "../projects/testimonials-grid-section/index.html",
        thumbnail: "../projects/thumbnails/testimonials-grid-section.png",
        frontendmentor: true,
        freelance: false,
        difficulty: "junior",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "3D Artist's Portfolio",
        URL: "../projects/dzirun_portfolio/index.html",
        thumbnail: "../projects/thumbnails/dzirun_portfolio.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: false,
        pet: true,
        game: false,
        langs: "HTML/CSS",
        description: ""
    },
    {
        name: "JS Calculator",
        URL: "https://nitalewska.github.io/calc_js_basics/",
        thumbnail: "../projects/thumbnails/calc-js.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: false,
        pet: true,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "News Homepage",
        URL: "https://nitalewska.github.io/news-homepage/",
        thumbnail: "../projects/thumbnails/news-homepage.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "junior",
        favourite: false,
        pet: false,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    },
    {
        name: "Advice generator app",
        URL: "https://nitalewska.github.io/Advice-generator-app/",
        thumbnail: "../projects/thumbnails/Advice-generator-app.jpg",
        frontendmentor: true,
        freelance: false,
        difficulty: "junior",
        favourite: true,
        pet: false,
        game: false,
        langs: "HTML/CSS/JS/API",
        description: ""
    },
    {
        name: "Tenzies",
        URL: "https://nitalewska.github.io/tenzies-game-react/",
        thumbnail: "../projects/thumbnails/tenzies-game-react.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: false,
        pet: true,
        game: true,
        langs: "HTML/CSS/JS/ReactJS",
        description: ""
    },
    {
        name: "Health Test",
        URL: "https://nitalewska.github.io/health_test/",
        thumbnail: "../projects/thumbnails/health_test.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: false,
        pet: true,
        game: false,
        langs: "HTML/CSS/JS/ReactJS",
        description: ""
    },
    // {
    //     name: "Yelp Camp",
    //     URL: "https://yelpcamp-production-c226.up.railway.app",
    //     thumbnail: "../projects/thumbnails/yelp-camp.jpg",
    //     frontendmentor: false,
    //     freelance: false,
    //     difficulty: "",
    //     favourite: true,
    //     pet: true,
    //     game: false,
    //     langs: "ExpressJS/MongoDB",
    //     description: ""
    // },
    {
        name: "Tzeezotje",
        URL: "https://nitalewska.github.io/testtask-Tzeezotje/",
        thumbnail: "../projects/thumbnails/tzeezotje.jpg",
        frontendmentor: false,
        freelance: false,
        difficulty: "",
        favourite: false,
        pet: true,
        game: false,
        langs: "HTML/CSS/JS",
        description: ""
    }
]

export default data