import './Header.css'
import React from 'react'

export default function Header() {
    return(
        <header className=''>
            <h1>Anna Muzalevskaia</h1>
            <h2>Web Developer</h2>
        </header>
    )
}